import React from "react"
import Layout from "../layouts/layout"
import styled from 'styled-components';
import { PageWrapper, MaxContainer, Container } from "../elements"
import { below } from "../utilities"
import SEO from "../components/seo"
import Sidebar from "../components/sidebar"
import Typist from 'react-typist'
import TypistLoop from 'react-typist-loop'

const IndexPage = () => (
  <Layout>

    <SEO title="Homepage | Chad Campbell" 
         description="Front-end software developer based in NYC"
         keywords={[`software developer`, `front end developer`, `react`, `gatsby`]}
         className='index'
    />

    <PageWrapper>
      <MaxContainer>
        <Container>
          <HomeHero>
            <article>
              <h4>Software Developer</h4>
              <h1>Chad Campbell</h1>
              <a href="https://www.kurtnoble.com/" target="_blank" rel="noopener noreferrer">@KNI</a>
              
              <div className="copy">
                <p>Hiya! My name is Chad. I'm a front end developer based in New York.</p>
              
                <div className="type-wrap">
                  <p>Techonologies I work with: </p>
                    <TypistLoop interval={1000}>
                    {[
                      'JavaScript',
                      'React',
                      'GraphQL',
                      'Redux',
                      'Node',
                      'CSS',
                      'Ruby',
                      'PHP',
                      'Flexbox',
                      'CSS Grid',
                      'Sass',
                      'Next.js',
                      'WordPress',
                      'Static Site Generators',
                      'GatsbyJs',       
                      'SQL'
                    ].map(text => <Typist key={text} startDelay={1000}>{text}</Typist>)}
                  </TypistLoop>
                </div>
              </div>
            </article>    
            <Sidebar />
          </HomeHero>
        </Container>
      </MaxContainer>
    </PageWrapper>

  </Layout>
)

const HomeHero= styled.div`
  display: flex;
  justify-content: space-between;
  ${below.small`
    flex-direction: column;
  `}

  h1 {

    font-size: calc(50px + (60 - 30) * ((100vw - 320px) / (1600 - 320)));
    margin-top: 0;
  }

  h4 {
    margin: 0;
  }

  a {
    font-family: 'Rubik-Medium';
    font-size: 24px;
    color: #8ECEA8;
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    

    a {
      color: black;
      display: block;
      padding: 10px;
    }
  }
  
  article {
    padding: 70px 0;
  }
  
  h4 {
    display: inline-block;
    border-bottom: 1px solid black;
  }
  
  .copy {
    margin-top: 30px;
    max-width: 900px;
  }

  p {
    line-height: 1.5em;
  }
  
  p:last-of-type {
    margin-bottom: 40px;
  }

  article {
    width: 65%;
    padding-right: 2.5%;
    position: relative;

    ${below.small`
      width: 100%;
      padding: 70px 0 40px;
    `}

    &:after {
      position: absolute;
      top: 15%;
      bottom: 15%;
      right: 0;
      content: '';
      height: 70%;
      width: 1px;
      background: rgba(0,0,0,.1);

      ${below.small`
        width: 100%;
        height: 1px;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
      `}
    }
  }

  div.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 32%;
    padding: 0 2.5% 0 0;

    ${below.small`
      width: 100%;
      padding: 40px 0 0;
    `}
  }

  .type-wrap {
    display: flex;
    align-items: center;

    ${below.small`
      flex-direction: column;
      align-items: initial;
    `}

    p {
      margin: 0;
    }

    div.Typist {
      font-family: 'Roboto Mono', monospace;
      font-weight: 600;
      margin-left: 5px;

      ${below.small`
       margin-left: 0;
      `}
    }
  }
`;

export default IndexPage
