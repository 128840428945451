import React from 'react'

const sidebar = () => (
  <div className="sidebar">   
    <p>What I'm geeking out on: <span>Tailwind CSS</span></p>
    <p>What I'm testing: <span>Gatsby 3.0</span></p>
    <p>What I'm building: <span>Probably a WordPress Site</span></p>
 </div> 
)

export default sidebar
